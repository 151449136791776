import "./Tiles.css";
import TandCfooter from "./TandCfooter"
import {taggingHelper} from '../helpers/taggingHelper'

const HotbuyTile = ({ info : { productUrl, shortDescription, price, heading, tnc, custom_title, show_bonus_image, overlay_image, symbol, pk, promoPrice, categories }, bgImage, sku, headerColor, largeTile,swipeable,index,dragging, sliderID,swipeDirection,sliderLength,pageUrl}) => {

  //console.log('HotbuyTile props:', productUrl, shortDescription, price, heading, tnc, custom_title, show_bonus_image, overlay_image, symbol, pk)
  const imageUrl = `https://thegoodguys.sirv.com/Content/WhiteTilesSKUs_New/${sku}.png?w=280&q=50`;
  //swipeable && swipeDirection !== undefined ? console.log('swipeDirection:',swipeDirection) : console.log('direction is undefined')
  const reducedLink = productUrl ? productUrl.slice(26) : null;

  let label_image;
  if (promoPrice && promoPrice.promoPriceType && promoPrice.promoPriceType.toLowerCase() === "black friday") {
    label_image = 
    <img
      className="c-hot-buy__black-friday-img unselectable"
      src="https://thegoodguys.sirv.com/dm/homepage-white-tiles/Assets/black-friday-deal.svg"
      loading="lazy"
      width="110"
      height="16"
      alt="BLACK FRIDAY DEAL!"
    />;
  } else 
  if (promoPrice && promoPrice.promoPriceType && promoPrice.promoPriceType.toLowerCase() === "knockout") {
    label_image = 
    <img
      className="c-hot-buy__knockout-img unselectable"
      src="https://thegoodguys.sirv.com/Content/2025FY/boxing-day/Homepage/boxing-day-deal-white.svg"
      loading="lazy"
      width="92"
      height="14"
      alt="KNOCKOUT DEAL!"
    />;
  } else {
    label_image = 
    <img
      className="c-hot-buy__hot-buy-img unselectable"
      src="https://thegoodguys.sirv.com/dm/homepage-acoustic-images/White%20Tiles/homepage-whitetile-hotbuy2_%402x.png?w=148&q=50"
      loading="lazy"
      sizes="118px"
      width="96"
      height="24"
      alt="Hot Buy"
    />;
  }

  return (
    <div
    className={`whitetiles-card__wrapper border-all border-graylight bg-white marg-5 pos-relative unselectable ${
      swipeable ? "marg-right-20 marg-left-20" : ""}`} 
      onTouchEnd={() => {
        //console.log('onTouchEnd event triggered Hotbuy', `Swipe Direction ${swipeDirection}`);
        if (swipeable && swipeDirection !== undefined) {
          taggingHelper(
            `Swipe Direction ${swipeDirection}`,
            swipeDirection === 'Left' 
              ? (index + 1 === sliderLength ? '1' : index + 2)
              : (index  <= 0 ? sliderLength : index ),
            sliderID,
            'type1'
          );
        }
      }}
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}>
      <a
        href={productUrl}
        onClick={(e) => {
          dragging ? e.preventDefault() : taggingHelper(pageUrl, reducedLink,index+1,sliderID)}}
        className={`home-white-tile unselectable ${
          largeTile ? "home-white-tile__inner" : "home-white-tile__innerSmall"
        }`}>
        <section className="body-tile-copy unselectable">
        <header className="home-white-tile-header marg-top-10 unselectable" style={{ backgroundColor: headerColor || "e3041a" }}>
          {custom_title !== "" ? (
              <>
                {custom_title}
                <sup>{symbol}</sup>
              </>
              ) : ( "HOT BUY!")}
          </header>
          <div className="clearfix marg-top-5 unselectable">
            <div className="home-white-tile__left-col marg-top-0 unselectable">
              <img
                src={imageUrl}
                loading="lazy"
                alt={heading}
                className="img-responsive padd-top-5 unselectable"
                width="280"
                height="372"
                draggable="false"
              />
            </div>
            <div className="home-white-tile__right-col text-left unselectable">
              <p className="home-white-tile__product-name text-black text-medium line-height-xs padd-top-5 unselectable">
                {shortDescription}
              </p>
              <div className="home-white-tile__price-lock-up unselectable">
                <div className={`c-hot-buy
                                  ${promoPrice && promoPrice.promoPriceType === "BLACK FRIDAY" && "c-hot-buy--black"}
                                  ${promoPrice && promoPrice.promoPriceType === "KNOCKOUT" && "c-hot-buy--reversed c-hot-buy--knockout"}
                                  home-white-tile__hot-buy shadow-1 unselectable`}>
                  <span className="c-hot-buy__hot-buy">
                      {label_image}
                  </span>
                    <span className="c-hot-buy__price unselectable">
                      <sup>$</sup>
                      {price}
                      {pk ? <span className="text-size-75">pk</span>: null }
                      <span className="c-hot-buy__price-suffix"></span> 
                    </span>
                </div>
                {show_bonus_image === true ? (
                  <div className="home-white-tile__extra-img unselectable">
                    <img
                      alt="bonus"
                      className="img-responsive"
                      src="https://thegoodguys.sirv.com/dm/homepage-acoustic-images/White%20Tiles/bonus-2x.png?q=50"
                      loading="lazy"
                    />
                  </div>
                ) : (
                  <div className="home-white-tile__extra-img-empty unselectable"></div>
                )}
              </div>
            </div>
          </div>
          {overlay_image !== "" ? (
            <img
              alt="The Good Guys Sale"
              className="home-white-tile_overlay-img img-responsive pos-absolute unselectable"
              loading="lazy"
              src={overlay_image}
              width="140"
              height="52"
            />
          ) : null}
        </section>
        {tnc !== "" ? <TandCfooter info={tnc} /> : null}
      </a>
      <input hidden className="white_tile_cat_l1" data-wt-cat={categories && categories.l1 && categories.l1.name} />
      <input hidden className="white_tile_cat_l2" data-wt-cat={categories && categories.l2 && categories.l2.name} />
      <input hidden className="white_tile_cat_l3" data-wt-cat={categories && categories.l3 && categories.l3.name} />
    </div>
  );
};

export default HotbuyTile;
